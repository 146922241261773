@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	background-color: #fff;
	color: #000;
  font-family: 'Poppins', sans-serif;
	margin: 0;
}

.height {
  height: 131%;
}



.detail>div {
  width: 100% !important;
  left: 0px !important;
  top: 0px !important;
  position: static !important;
  margin-top: 10px !important;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: inherit;
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #babdc0;
  border-radius: 2rem;
  transition: all ease-in-out 2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #818283;
}

.truncate2{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
}

.truncates{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
}


.arrow.open {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  color: #2E8B4A;
}

.arrow.close {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
}

.arrow.opens {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  color: #2E8B4A;
}

.arrow.closes {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
}





.slick-arrow.slick-prev, 
.slick-arrow.slick-next{
  border:1px solid #EBEDF0;
  background-color: white;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-size: 22px !important;
  border-radius: 50%;
  color: #101828;
  overflow: hidden;
  position: relative;
}

.slick-arrow.slick-prev{
  position: absolute;
  left: -60px;
    /* transition: all 0.2s ease-in-out; */
}

.slick-arrow.slick-next{
  position: absolute;
  right: -60px;
    /* transition: all 0.2s ease-in-out; */
}

.slick-arrow.slick-prev:hover, 
.slick-arrow.slick-next:hover{
  color:#101828;

  background-color: #F9F9F9;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  /* transform: scale(1); */
}
.slick-prev::before, .slick-next::before{
  display: none;
}


/* Custom Slick Dots */
@keyframes loading {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

/* specific image carousel */
.ft-slick__dots--customImage {
  height: 10px;
  width: 10px;
  background-color: #babdc0;
  border-radius: 100%;
  position: absolute;
  left: 0;
  margin-top: 20px;
}
.slick-dots .slick-active .ft-slick__dots--customImage {
  width: 40px;
  top: 0;
  overflow: hidden;
  border-radius: 4px;
  height: 10px;
  background-color: #1E5DE2;
}

/* common carousel css */
.slick-dots li {
  width: 10px;
  margin: 0 2px;
  transition: width 0.3s ease-in-out;
}

.slick-dots .slick-active {
  width: 50px;
  transition: width 0.3s ease-in-out;
  border-radius: 4px;

}

.slick-dots .slick-active .ft-slick__dots--customImage {
  width: 40px;
  top: 0;
  overflow: hidden;
  border-radius: 4px;
  height: 10px;
}

.slick-dots .slick-active .ft-slick__dots--customImage .loading {
  height: 10px;
  animation: loading 5s ease-in;
  background-image: linear-gradient(270deg, #3266CC, #3266CC);
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
}




@media only screen and (max-width: 1279px) {
  
  .slick-arrow.slick-prev{
    position: absolute;
    left: -20px;

  }
  .slick-arrow.slick-next{
    position: absolute;
    right: -20px;
  }

}



@media only screen and (max-width: 600px) {
  
  .slick-arrow.slick-prev{
    position: absolute;
    left: -10px;

  }

  .slick-arrow.slick-next{
    position: absolute;
    right: -10px;
  }

  
  .slick-arrow.slick-prev, 
.slick-arrow.slick-next{
  width: 40px;
  height: 40px;

  font-size: 16px !important;

}
}




